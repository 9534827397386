<template>
  <b-card no-body :img-src="toy.image" :img-alt="toy.name" img-top>
    <b-card-title
      class="flex-1 px-2 py-2 m-0 d-flex justify-content-between border-top"
      :class="statusClass"
    >
      <b-dropdown v-if="isActive" no-caret class="btn-cancel">
        <template #button-content>
          <span class="fas fa-ellipsis-v" />
        </template>
        <b-dropdown-item @click="onTriggerAction(RENT_ACTIONS.TRANSFER)">
          <span class="fas fa-exchange-alt mr-1" /> Transferir Aluguel
        </b-dropdown-item>
        <b-dropdown-item @click="onAddExtraTime()">
          <span class="fas fa-clock mr-1" /> Adicionar Tempo Extra
        </b-dropdown-item>
        <b-dropdown-item
          variant="danger"
          @click="onTriggerAction(RENT_ACTIONS.CANCEL)"
        >
          <span class="fas fa-ban mr-1" /> Cancelar
        </b-dropdown-item>
      </b-dropdown>
      <div class="w-100">
        <small class="d-flex gap-1">
          <span>
            {{ toy.name }}
          </span>
          <em v-if="isPaused"> (pausado) </em>
        </small>
        <span v-if="isActive || isPaused" class="d-flex flex-wrap">
          {{ toy.rent.customer.name }}
        </span>
      </div>
    </b-card-title>
    <b-card-text
      v-if="isActive || isPaused"
      class="p-2 m-0"
      :class="statusClass"
    >
      <div class="display-table">
        <div>
          <div class="text-right pr-2">
            <small><b>Período:</b></small>
          </div>
          <div>
            <small class="mr-2">{{ period }}</small>
            <b-button
              size="sm"
              variant="clear-primary"
              class="p-0"
              @click="onTriggerAction(RENT_ACTIONS.CHANGE_PERIOD)"
            >
              <span class="fas fa-redo" />
            </b-button>
          </div>
        </div>
        <div>
          <div class="text-right pr-2">
            <small><b>Tempo:</b></small>
          </div>
          <div>
            <small class="mr-2"> {{ elapsedTime }} min </small>
          </div>
        </div>

        <div v-if="extraTime">
          <div class="text-right pr-2">
            <small><b>Tempo Extra:</b></small>
          </div>
          <div>
            <small class="mr-2"> {{ extraTime }} min </small>
          </div>
        </div>

        <div v-if="pausedTime">
          <div class="text-right pr-2">
            <small><b>Pausado:</b></small>
          </div>
          <div>
            <small class="mr-2"> {{ pausedTime }} min </small>
          </div>
        </div>

        <div>
          <div class="text-right pr-2">
            <small><b>Início:</b></small>
          </div>
          <div>
            <small>{{ createdAt }}</small>
          </div>
        </div>

        <div>
          <div class="text-right pr-2">
            <small><b>Retorno:</b></small>
          </div>
          <div>
            <small>{{ endsAt }}</small>
          </div>
        </div>
        <div>
          <div class="text-right pr-2">
            <small><b>Valor:</b></small>
          </div>
          <div>
            <small>
              {{ amount | formatCurrency }}
            </small>
          </div>
        </div>
      </div>
      <div class="d-flex gap-1 flex-column mt-3">
        <div class="d-flex flex-wrap justify-content-stretch gap-1">
          <b-button
            variant="success"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.CASH)"
            >DI</b-button
          >
          <b-button
            variant="warning"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.CREDIT_CARD)"
            >CC</b-button
          >
          <b-button
            variant="info"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.DEBIT_CARD)"
            >CD</b-button
          >
          <b-button
            variant="secondary"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.PIX)"
            >PIX</b-button
          >
          <b-button
            variant="outline-info"
            class="flex-1"
            @click="onCloseRent()"
          >
            <span class="fas fa-plus-circle" />
          </b-button>
        </div>
        <!-- <div>
          <ProductRentToggle :toy="toy" @trigger-action="onTriggerAction" />
        </div> -->
      </div>
    </b-card-text>
    <template v-if="!disabled" #footer>
      <div class="actions d-flex flex-column gap-0">
        <b-button
          v-if="isPaused"
          variant="outline"
          style="border-radius: 0 0 0.2rem 0.2rem !important"
          class="w-100 text-success"
          @click="onTriggerAction(RENT_ACTIONS.RESUME)"
        >
          <span class="fas fa-play mr-1" />
          Resumir
        </b-button>
        <template v-else-if="isActive">
          <b-button
            variant="outline"
            class="w-100 rounded-0 text-muted"
            @click="onTriggerAction(RENT_ACTIONS.PAUSE)"
          >
            <span class="fas fa-pause mr-1" />
            Pausar
          </b-button>
          <!-- <b-button
            style="border-radius: 0 0 0 0.2rem !important"
            variant="danger"
            class="w-100 rounded-0"
            @click="onTriggerAction(RENT_ACTIONS.CLOSE)"
          >
            <span class="fas fa-times mr-1" />
            Encerrar
          </b-button> -->
        </template>

        <div v-else class="w-100">
          <b-button
            variant="success"
            class="w-100 rounded-0"
            @click="onTriggerAction(RENT_ACTIONS.START)"
          >
            <span class="fas fa-flag-checkered mr-1" />
            Iniciar
          </b-button>
          <b-button
            variant="outline-success"
            style="border-radius: 0 0 0.2rem 0.2rem !important"
            class="w-100 rounded-0"
            @click="onTriggerAction(RENT_ACTIONS.REOPEN_LAST_RENT)"
          >
            <span class="fas fa-redo mr-1" />
            Voltar último aluguel
          </b-button>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { PAYMENT_METHODS_IDS, RENT_ACTIONS } from '@/constants'
import { format, differenceInMinutes } from 'date-fns'
import { formatCurrency } from '@/helpers/numbers'
import {
  calculateEndDate,
  calculatePeriod,
  calculateRentAmount,
  calculatePausedTime,
  calculateExtraTime,
  isActive,
  isDelayed,
  isPaused
} from '@/helpers/rents'
// import ProductRentToggle from './ProductRentToggle'
import services from '@/services'
import { isEmpty } from 'lodash/fp'

export default {
  name: 'ToyRentListItem',
  // components: {
  //   ProductRentToggle
  // },
  filters: {
    formatCurrency
  },
  props: {
    toy: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      intervals: {},
      elapsedTime: 0,
      rentAmount: 0,
      productsAmount: 0
    }
  },
  computed: {
    ...mapState('store', ['currentStore']),
    ...mapGetters('periods', ['periodsSortedByPrice']),
    isActive() {
      return isActive(this.toy.rent)
    },
    isPaused() {
      return isPaused(this.toy.rent)
    },
    isDelayed() {
      return isDelayed(this.endDate)
    },
    period() {
      return calculatePeriod(this.toy.rent)
    },
    createdAt() {
      if (this.toy.rent) {
        const createdAt = new Date(this.toy.rent.createdAt)
        return format(createdAt, "HH'h'mm")
      }

      return ''
    },
    endsAt() {
      return this.toy.rent ? format(this.endDate, "HH'h'mm") : ''
    },
    endDate() {
      return calculateEndDate(this.toy.rent)
    },
    extraTime() {
      return this.toy.rent.extraTime
        ?.filter(({ type }) => type !== 'pause')
        .reduce((acc, { minutes }) => (acc += minutes), 0)
    },
    pausedTime() {
      return calculatePausedTime(this.toy.rent)
    },
    hasProducts() {
      return this.toy.rent?.product?.length > 0
    },
    statusClass() {
      if (this.isPaused) {
        return 'alert-warning'
      }

      if (this.isDelayed) {
        return 'alert-danger'
      }

      return ''
    },
    amount() {
      return this.rentAmount + this.productsAmount
    }
  },
  created() {
    this.RENT_ACTIONS = RENT_ACTIONS
    this.PAYMENT_METHODS_IDS = PAYMENT_METHODS_IDS
    const toyId = this.toy.id

    if (this.isActive && !this.isPaused) {
      const interval = setInterval(() => {
        this.setAmount()
        this.setElapsedTime()
      }, 1000)
      this.$emit('set-toy-update-interval', { toyId, interval })
    } else {
      this.$emit('clear-toy-update-interval', { toyId })
    }

    this.setElapsedTime()
    this.setAmount()
  },
  methods: {
    setElapsedTime() {
      if (isEmpty(this.toy.rent)) {
        return
      }

      const { createdAt, pausedAt } = this.toy.rent
      const fromDate = this.isPaused ? new Date(pausedAt) : new Date()
      const pausedTime = calculatePausedTime(this.toy.rent)
      const extraTime = calculateExtraTime(this.toy.rent)

      const elapsedTime =
        differenceInMinutes(fromDate, new Date(createdAt)) -
        pausedTime -
        extraTime

      this.elapsedTime = elapsedTime <= 0 ? 0 : elapsedTime
    },
    setAmount() {
      const { toy, elapsedTime, hasProducts, currentStore } = this
      const { delayFeePerMinute = 1, toleranceMinutes = 2 } = currentStore
      const { rent } = toy

      const { rentAmount, productsAmount } = calculateRentAmount({
        rent,
        elapsedTime,
        hasProducts,
        delayFeePerMinute,
        toleranceMinutes
      })

      this.rentAmount = rentAmount
      this.productsAmount = productsAmount

      this.checkPeriod()
    },
    onCloseRent(paymentMethod) {
      const { elapsedTime, hasProducts } = this

      this.onTriggerAction(RENT_ACTIONS.CLOSE, {
        elapsedTime,
        hasProducts,
        paymentMethod
      })
    },
    onAddExtraTime() {
      const { elapsedTime, rentAmount } = this

      this.onTriggerAction(RENT_ACTIONS.ADD_EXTRA_TIME, {
        elapsedTime,
        rentAmount
      })
    },
    onTriggerAction(action, data) {
      const { toy } = this
      this.$emit('trigger-action', { action, toy, data })
    },
    async checkPeriod() {
      if (isEmpty(this.toy.rent)) {
        return
      }

      if (
        !isEmpty(this.toy.rent?.endedAt) ||
        !isEmpty(this.toy.rent?.canceledAt)
      ) {
        return
      }

      const { period } = this.toy.rent

      const rentAmount = this.rentAmount
      const periodPrice = period.price

      let nextPeriod = this.periodsSortedByPrice.find(({ id, price }) => {
        return id !== period.id && price > periodPrice && price < rentAmount
      })

      if (nextPeriod) {
        this.toy.rent.period = nextPeriod

        await services.rents.updateRent({
          ...this.toy.rent,
          periodId: nextPeriod.id
        })

        this.$emit('reload')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-footer {
  padding: 0px;
}

.actions {
  column-count: 4;
}
.card-title {
  font-size: 1rem;
  font-weight: bold;
}

.display-table {
  display: table;
}
.display-table > div {
  display: table-row;
}
.display-table > div > div {
  display: table-cell;
}

.card {
  max-width: calc((100% / 3) - 0.5em);
  flex: 1 0 calc((100% / 3) - 0.5em);

  @media (max-width: 768px) {
    max-width: calc(100% / 2);
    flex: 1 0 calc((100% / 2) - 0.5em);
  }

  // @media (min-width: 1200px) {
  //   max-width: calc((100% / 4) - 0.5em);
  //   flex: 1 0 calc((100% / 4) - 0.5em);
  // }

  @media (min-width: 1200px) {
    max-width: calc((100% / 4) - 0.5em);
    flex: 1 0 calc((100% / 4) - 0.5em);
  }
}

.toy-name {
  font-size: calc(1.7vh);
}

.btn-cancel {
  position: absolute;
  right: 10px;
  top: 10px;
}

.btn {
  font-size: 13px;
}
</style>
