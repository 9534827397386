<template>
  <b-form class="ToyRentCloseModalForm" novalidate>
    <ToyRentDetails :toy="toy" hide-total />
    <b-form-group label="Escolha um novo brinquedo" label-for="toy">
      <ToySelect
        id="toy"
        v-model="form.toy"
        :validation="$v.form.toy"
        :filter="onFilter"
        :reduce="onReduce"
      />
      <b-form-invalid-feedback v-if="$v.form.toy.$invalid">
        Informe o brinquedo para o qual deseja transferir este aluguel
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'
import ToySelect from '@/components/Toys/ToySelect'
import ToyRentDetails from './ToyRentDetails'

export default {
  name: 'ToyRentTransferModalForm',
  components: {
    ToySelect,
    ToyRentDetails
  },
  mixins: [withFormValidation({ fields: ['toy'] })],
  validations: {
    form: {
      toy: { required }
    }
  },
  props: {
    toy: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    extraTime() {
      return this.toy.rent.extraTime
        .map(({ time }) => `+ ${time} minutos`)
        .join('\n')
    }
  },
  methods: {
    onFilter(toys) {
      return toys.filter(({ id }) => id !== this.toy.id)
    },
    onReduce: (toy) => toy
  }
}
</script>
