<template>
  <BaseSelect
    v-model="toy"
    :options="options"
    :reduce="reduce"
    :validation="validation"
  />
</template>

<script>
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import BaseSelect from '@/components/BaseSelect'

export default {
  name: 'ToySelect',
  components: {
    BaseSelect
  },
  mixins: [
    withAsyncAction({
      key: 'toys',
      initialState: [],
      fetcher: {
        methodName: 'fetchToys',
        handler: services.toys.fetchToys
      }
    })
  ],
  props: {
    value: {
      type: [Number, Object],
      default: null
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    filter: {
      type: Function,
      default: (toys) => toys
    },
    reduce: {
      type: Function,
      default: ({ code }) => code
    }
  },
  computed: {
    toy: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    options() {
      return this.filter(this.toys.data).map(({ id, name }) => ({
        code: id,
        label: name
      }))
    }
  },
  created() {
    this.fetchToys()
  }
}
</script>
