<template>
  <b-form-group label="Detalhes do Aluguel" label-for="rent-details">
    <b-card class="mb-2">
      <b-row>
        <b-col>
          <b-form-group label="Cliente">
            <b-input
              class="border-0"
              readonly
              :value="toy.rent.customer.name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Brinquedo">
            <b-input class="border-0" readonly :value="toy.name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Período">
            <b-input
              class="border-0"
              readonly
              :value="`${toy.rent.period.time} min - ${formatCurrency(
                toy.rent.period.price
              )}`"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group v-if="delay" label="Atraso">
            <b-input class="border-0" readonly :value="`${delay} minuto(s)`" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Tempo Extra">
        <b-textarea class="border-0" readonly :value="extraTime" no-resize />
      </b-form-group>

      <b-form-group v-if="!hideTotal" label="Valor Total">
        <b-input class="border-0" readonly :value="totalAmount" />
      </b-form-group>
    </b-card>
  </b-form-group>
</template>

<script>
import { formatCurrency } from '@/helpers/numbers'

export default {
  name: 'ToyRentDetails',
  filters: {
    formatCurrency
  },
  props: {
    toy: {
      type: Object,
      required: true
    },
    delay: {
      type: Number,
      default: null
    },
    hideTotal: {
      type: Boolean
    }
  },
  computed: {
    extraTime() {
      return this.toy.rent.extraTime
        ?.map(({ minutes }) => `+ ${minutes} minutos`)
        .join('\n')
    },
    totalAmount() {
      return formatCurrency(this.toy.rent.period.price)
    }
  },
  methods: {
    formatCurrency
  }
}
</script>
